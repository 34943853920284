<template>
  <!-- 会员活动核销 -->
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input
            placeholder="请输入手机号"
            class="search-input ml-10"
            v-model="form.phone"
          ></el-input>
          <el-select
            placeholder="请选择核销活动"
            class="search-input ml-10"
            filterable
            clearable
            v-model="form.activityId"
          >
            <el-option
              v-for="(item, index) in actList"
              :key="index"
              :label="item.activityName"
              :value="item.activityId"
            ></el-option>
          </el-select>
          <el-date-picker
            class="ml-10"
            v-model="date"
            value-format="yyyy-MM-dd hh:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00','23:59:59']"
          ></el-date-picker>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-10"
            @click="getList"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" class="ml-10" @click="reset"
            >重置</el-button
          >
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 表格 -->
          <el-table
            class="mt-24 member-table"
            :data="tableData"
          >
            <el-table-column
              label="门店名称"
              prop="storeName"
            ></el-table-column>
            <el-table-column label="用户" prop="name"></el-table-column>
            <el-table-column label="联系方式" prop="phone"></el-table-column>
            <el-table-column
              label="核销活动"
              prop="activityName"
            ></el-table-column>
            <el-table-column label="报名方式" prop="enrollCondition">
              <template slot-scope="scope">{{
                scope.row.enrollCondition == 1 ? "免费报名" : "积分报名"
              }}</template>
            </el-table-column>
            <el-table-column
              label="报名人数"
              prop="enrollPerson"
            ></el-table-column>
            <el-table-column label="核销人员" prop="name"></el-table-column>
            <el-table-column
              label="核销时间"
              prop="offsetTime"
            ></el-table-column>
          </el-table>
          <Pagination
            :total="total"
            :pageNum="form.pageNum"
            :pageSize="form.pageSize"
            :pageSizes="[10, 20, 30, 50, 100]"
            @changePage="getList"
            @syncPageData="syncPageData"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  getActivityList,
  getAllActivityNameList,
} from "@/api/verify_center/verify_records";
export default {
  name: "verify_records_activity",
  components: {
    Pagination,
  },
  data() {
    return {
      // 顶部搜索栏数据
      form: {
        pageNum: 1,
        pageSize: 10,
        phone: "",
        activityId: "",
        startTime: "",
        endTime: "",
      },
      date: [],
      actList: [],
      // 优惠券表格数据内容
      tableData: [],
      total: 0,
    };
  },
  mounted() {
    this.getList();
    this.getActivities();
  },
  methods: {
    getList() {
      this.form.startTime = this.date.length ? this.date[0] : "";
      this.form.endTime = this.date.length ? this.date[1] : "";
      getActivityList(this.form).then((res) => {
        this.total = res.data.body.total;
        this.tableData = res.data.body.list;
      });
    },
    getActivities() {
      //获取所有活动名称列表
      getAllActivityNameList().then((res) => {
        if (res.data.body) {
          this.actList = res.data.body;
        }
      });
    },
    // 重置搜索关键词
    reset() {
      this.form = {
        pageNum: 1,
        pageSize: 10,
        phone: "",
        activityId: "",
        startTime: "",
        endTime: "",
      };
      this.date = [];
      this.getList();
    },
    syncPageData(data) {
      this.form.pageNum = data.num;
      this.form.pageSize = data.size;
    },
  },
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
</style>
